var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"AssociatedItems"},[_c('div',{staticClass:"head_img"},[_c('div',{staticClass:"img_title"},[_c('div',{staticClass:"img"},[(_vm.demandinfo.projectImg)?_c('img',{attrs:{"src":_vm.demandinfo.projectImg,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/image/Group 3163.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_c('p',{staticClass:"merchant_name"},[_vm._v(_vm._s(_vm.demandinfo.projectName))]),_c('div',{staticClass:"storeCenter_item_top_left_flex_tag"},_vm._l((_vm.demandinfo.areasInvestigation),function(o,index){return _c('span',{key:index},[(index < 2)?_c('span',{staticClass:"office"},[_vm._v(_vm._s(o.areas[1]))]):_vm._e()])}),0)])])]),_c('div',{staticClass:"switch_tab"},[_c('div',{staticClass:"label_management"},[(false)?_c('div',{staticClass:"input-with"},[_c('el-input',{staticClass:"input-with-select",attrs:{"placeholder":"请输入用户名称"},model:{value:(_vm.queryInfo.wechatName),callback:function ($$v) {_vm.$set(_vm.queryInfo, "wechatName", $$v)},expression:"queryInfo.wechatName"}},[_c('el-button',{staticClass:"seach",attrs:{"slot":"append"},on:{"click":function($event){return _vm.search()}},slot:"append"},[_vm._v("搜索")])],1)],1):_vm._e(),_c('el-table',{staticClass:"in_table",staticStyle:{"width":"100%"},attrs:{"data":_vm.listData,"stripe":"","header-cell-style":{ background: '#F8F9FB', color: '#595959' }}},[_c('el-table-column',{attrs:{"label":"编号","align":"center","type":"index"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s((_vm.queryInfo.pageNum - 1) * _vm.queryInfo.pageSize + scope.$index + 1))])]}}])}),_c('el-table-column',{attrs:{"label":"用户头像","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.headImgUrl,"width":"42","height":"42","alt":""}})]}}])}),_c('el-table-column',{attrs:{"label":"姓名","prop":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.wechatName))])]}}])}),_c('el-table-column',{attrs:{"label":"职务","prop":"position","align":"center"}}),_c('el-table-column',{attrs:{"label":"手机号","prop":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.phone))])]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.state == 0)?_c('el-button',{staticStyle:{"color":"#146aff"},attrs:{"type":"text"},on:{"click":function($event){return _vm.contactClick(row)}}},[_vm._v("未联系 ")]):_vm._e(),(row.state == 1)?_c('el-button',{staticStyle:{"color":"#146aff"},attrs:{"type":"text"}},[_vm._v("已联系 ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"new_page"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[10, 30, 50],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }