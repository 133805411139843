<template>
  <div class="AssociatedItems">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img v-if="demandinfo.projectImg" :src="demandinfo.projectImg" alt="" />
          <img v-else src="../../assets/image/Group 3163.png" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ demandinfo.projectName }}</p>
          <div class="storeCenter_item_top_left_flex_tag">
            <span v-for="(o, index) in demandinfo.areasInvestigation" :key="index">
              <span v-if="index < 2" class="office">{{ o.areas[1] }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="switch_tab">
      <div class="label_management">
        <div class="input-with" v-if="false">
          <el-input
            placeholder="请输入用户名称"
            v-model="queryInfo.wechatName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
        </div>
        <el-table
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" type="index">
            <template slot-scope="scope">
              <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="用户头像" align="center">
            <template v-slot="{ row }">
              <img :src="row.headImgUrl" width="42" height="42" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.wechatName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="职务" prop="position" align="center"></el-table-column>

          <el-table-column label="手机号" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.phone }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template v-slot="{ row }">
              <el-button
                v-if="row.state == 0"
                style="color: #146aff"
                type="text"
                @click="contactClick(row)"
                >未联系
              </el-button>
              <el-button v-if="row.state == 1" style="color: #146aff" type="text"
                >已联系
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import { getdemand, getcontactUs, getdemandtop, contactUs } from '@/api/demand'
export default {
  name: 'contactUs',
  computed: {
    id() {
      return this.$route.query.id * 1
    }
  },
  data() {
    return {
      active: 2,
      //tab切换
      typeIndex: 1,
      form: {
        commodityCoverPicture: '',
        num: 1
      }, //表单收集
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        inspectDemandId: this.$route.query.id * 1,
        state: null, //状态（0：未联系；1：已联系）
        wechatName: null
      },
      listData: [],
      total: 0,
      demandinfo: {} //头部信息
    }
  },
  created() {
    this.getdemandtop()
    this.getInspectDemand()
  },
  methods: {
    //联系
    contactClick(row) {
      let query = { ...row }
      query.state = 1
      this.$alert('您确认已经联系过此客户？')
        .then(function () {
          return contactUs(query)
        })
        .then((res) => {
          if (res.data.resultCode === 200) {
            this.getInspectDemand()
            this.$message.success('联系成功。')
          }
        })
        .catch(() => {})
    },
    async getInspectDemand() {
      const res = await getcontactUs(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
      }
    },
    //最近更新时间
    async getdemandtop() {
      const res = await getdemandtop({ inspectDemandId: this.id })
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
        this.demandinfo.areasInvestigation = JSON.parse(this.demandinfo.areasInvestigation)
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getInspectDemand()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.getInspectDemand()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>
  <style lang="less" scoped>
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.AssociatedItems {
  .storeCenter_item_top_left_flex_tag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    padding-top: 13px;

    span {
      box-sizing: border-box;
      padding: 1px 6px;
      display: block;
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
    }

    .office {
      position: relative;
      left: -3px;
      padding: 5px;
      color: #6090ee;
      background: #e1ecff;
    }

    .support {
      border: 1px solid #fd523f;
      color: #fd523f;
      margin-left: 12px;
    }
  }
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;

    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
      }

      .title {
        margin: 0px 0px 0px 10px;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
          border-radius: 4px;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    padding: 10px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
